@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
#mountain-img{
    z-index: 2;
}
.welcome-text{
    font-family: 'Poppins', sans-serif;
    z-index: 1;
    animation: comeup 1.2s ease;
    transform-style: preserve-3d;
}
.welcome-text2{
    font-family: 'Poppins', sans-serif;
    z-index: 3;
    animation: comedown 1.2s ease;
    transform-style: preserve-3d;
}
@keyframes comeup {
    from{
        bottom: -10dvh;
    }
    to{
        bottom: 20dvh;
    }
}
@keyframes comedown {
    from{
        top: 0dvh;
    }
    to{
        top: 10dvh;
    }
}