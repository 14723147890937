@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
*{
    transition: all 0.3s ease;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
body{
    overflow-x: hidden;
    user-select: none;
    -ms-overflow-style: none;
}
